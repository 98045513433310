<template>
  <v-container fluid color="red">
    <v-row dense class="row-54">
      <v-col cols="12" class="p-0 m-0">
        <v-switch
          left
          v-if="cats.length > 1"
          class="float-left mt-0 mr-5"
          v-model="multiAgrupar"
          inset
          color="green"
          label="Agrupar comentario en Guía"
        ></v-switch>
        <v-tooltip left color="blue">
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="mostrarError.length > 0"
              v-on="on"
              v-if="nuevo"
              class="float-right"
              color="green"
              fab
              x-small
              dark
              @click="anadirCat()"
            >
              <v-icon>mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <span>Añadir Nueva categoría/comentario</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <div v-for="(cat, index) in cats" :key="index">
          <ficha-esp-cat-form
            :datos="cat"
            :compRules="compRules"
            :compCategorias="compCategorias"
            :subcategorias="subcategorias"
            :index="index"
            :mostrarError="mostrarError"
            @emitCambioCat="cambioCat"
            @emitEliminar="eliminarCat"
          ></ficha-esp-cat-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FichaEspCatForm from "./FichaEspCatForm";
export default {
  name: "ficha-esp-multi",
  props: ["compRules", "compCategorias", "subcategorias", "nuevo"],
  components: {
    FichaEspCatForm
  },

  data: () => ({
    cats: [],
    multiAgrupar: false,
    mostrarError: []
  }),
  methods: {
    ...mapActions("comun", ["setSnackBar"]),
    /* funcion para realizar los cambios en el form hijo en tiempo real */
    cambioCat(data) {
      const i = data.id;
      const cat = data.resp_idcategory;
      const scat = data.resp_idsubcategory;

      this.cats[i].resp_idcategory = cat;
      this.cats[i].resp_idsubcategory = scat;
    },
    /* funcion para comprobar si tenemos una cat/es repetida y la mostramos */
    comprobarRepetidos() {
      this.mostrarError = [];
      if (this.cats.length === 1) {
        return;
      }

      for (const c of this.cats) {
        const cat = c.resp_idcategory;
        const scat = c.resp_idsubcategory;
        const id = c.id;

        if (!cat || !scat) {
          continue;
        }
        const cont = this.cats
          .filter(
            f =>
              f.resp_idcategory === cat &&
              f.resp_idsubcategory === scat &&
              f.id != id
          )
          .map(f => f.id);
        this.mostrarError.push(...cont);
        if (this.mostrarError.length > 0) return;
        // console.log(cat, scat, cont);
      }
    },
    anadirCat() {
      this.cats.push({
        id: this.cats.length,
        resp_idcategory: null,
        resp_idsubcategory: null
      });
    },
    eliminarCat(data) {
      this.cats.splice(data, 1);
      this.cats.forEach((c, idx) => (this.cats[idx].id = idx));
      this.mostrarError = null;
    },
    /* funcion para enviar al parent los datos y así poder gestionarlos en la bd */
    enviarParent() {
      this.comprobarRepetidos();
      const blForm = this.mostrarError.length > 0 ? true : false;
      // console.log(blForm);
      this.$emit("emitBloquearForm", blForm);

      this.$emit("emitCategoriasMulti", {
        cats: this.cats,
        agrupar: this.multiAgrupar
      });
    }
  },
  watch: {
    cats: {
      immediate: true,
      deep: true,
      handler() {
        this.enviarParent();
      }
    },
    multiAgrupar() {
      this.enviarParent();
    }
  },

  mounted() {
    // añadimos un item de categoria
    this.anadirCat();
  }
};
</script>

<style scoped>
.row-54 {
  height: 54px;
}
</style>
