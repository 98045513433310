<template>
  <v-container fluid v-if="!isLoading">
    <v-form ref="form" v-model="validForm">
      <v-row dense>
        <v-col cols="12" md="12">
          <v-card flat outlined tile>
            <v-card-title>
              <div class="headline">
                {{ nuevaDireccion ? "Nueva Dirección" : "Editando dirección" }}
              </div>
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="direccion.read_address"
                    label="Dirección"
                    outlined
                    dense
                    autocomplete="new"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="direccion.read_phone"
                    label="Teléfono"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                    autocomplete="new"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <!-- PASO POR EL PROP SELECTED TODOS LOS DATOS RELATIVOS A LA DIRECCION -->
                  <zipcodes-desp
                    @emitSeleccion="selectedZip"
                    :selected="direccion"
                    :requerido="true"
                  ></zipcodes-desp>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    class="float-right mx-1 mt-4"
                    outlined
                    small
                    color="primary"
                    dark
                    @click="guardarDireccion"
                    >Guardar</v-btn
                  >
                  <v-btn
                    class="float-right mx-1 mt-4"
                    outlined
                    small
                    color="red"
                    dark
                    @click="$emit('emitCancelar')"
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ZipcodesDesp from "@/components/comun/ZipcodesDesp";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "FichaDirecForm",
  components: {
    ZipcodesDesp,
  },
  props: ["propDireccion", "reco_id"],
  data: () => ({
    validForm: true,
    isLoading: true,
    nuevaDireccion: false,
    direccion: {
      read_address: null,
      read_phone: null,
      read_idcp: null,
      zipdata: {
        zico_id: null,
        zico_code: null,
        zico_town: null,
        zico_province: null,
      },
    },
    /* REGLAS DE VALIDACIÓN */
    textoRegla: [(v) => !!v || "Este campo es requerido"],
  }),
  methods: {
    ...mapActions("records", ["setRecordAddressNew", "setRecordAddressUpdate"]),
    ...mapActions("comun", ["setSnackBar"]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),
    selectedZip(e) {
      this.direccion.read_idcp = e;
    },
    async guardarDireccion() {
      //validamos el formulario
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }
      // si es una nueva direccion
      if (this.nuevaDireccion) {
        //creamos una nueva direccion
        if (this.direccion.read_idcp === null || !this.direccion.read_address) {
          const miSnack = {
            mostrar: true,
            texto: "Hay campos obligatorios sin completar",
            color: "red",
          };
          this.setSnackBar(miSnack);
          return;
        }

        const datos = {
          reco_id: this.reco_id,
          ...this.direccion,
        };

        await this.setRecordAddressNew(datos);
      } else {
        //actualizamos una dirección existente

        const datos = {
          reco_id: this.reco_id,
          ...this.direccion,
        };
        // console.log("actualizamos datos", datos);

        await this.setRecordAddressUpdate(datos);
      }
      // cambiamos el estado al store para la recarga
      this.SET_RECORDS_RELOAD(true);
      this.$emit("emitCancelar");
    },
  },

  mounted() {
    if (!this.propDireccion) {
      this.nuevaDireccion = true;
    } else {
      this.direccion = JSON.parse(JSON.stringify(this.propDireccion));
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>
