<template>
  <v-container fluid v-if="!isLoading">
    <v-form ref="form" v-model="validForm">
      <v-row dense>
        <v-col cols="12" md="12">
          <v-card flat outlined tile>
            <v-card-title>
              <div class="headline">
                {{ editando === true ? "Editando Aviso" : " Nuevo Aviso" }}
              </div>
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" md="3">
                  <v-date-picker
                    first-day-of-week="1"
                    :color="colorSelected"
                    v-model="seg.reno_notificationdate"
                    full-width
                    class="mt-4"
                    :rules="[(v) => !!v || 'Este campo es requerido']"
                  >
                    <template v-slot:default>
                      <v-btn
                        :disabled="!seg.reno_notificationdate"
                        outlined
                        block
                        x-small
                        color="red"
                        @click="seg.reno_notificationdate = null"
                        >Eliminar Fecha Aviso</v-btn
                      >
                    </template>
                  </v-date-picker>
                </v-col>
                <v-col cols="12" md="9">
                  <v-textarea
                    outlined
                    height="380px"
                    class="mt-5 elevation-2"
                    counter="1000"
                    :value="seg.reno_comments"
                    v-model="seg.reno_comments"
                    :rules="[
                      (v) => !!v || 'Este campo es requerido',
                      rules.length(1000),
                    ]"
                    required
                    no-resize
                    clear-icon="mdi-close-circle"
                    label="Texto del aviso"
                    rows="11"
                    hint="Comentarios respecto al aviso"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                  ><v-btn
                    class="float-right mx-1"
                    outlined
                    small
                    color="primary"
                    dark
                    required
                    @click="guardarNoti"
                    >Guardar</v-btn
                  >
                  <v-btn
                    class="float-right mx-1"
                    outlined
                    small
                    color="red"
                    dark
                    @click="$emit('emitCancelar')"
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Ficha-seguimiento-form",
  props: ["seguimiento", "reco_id"],
  data: () => ({
    validForm: true,
    isLoading: true,
    editando: false,
    colorPicker: null,
    seg: {
      reno_id: null,
      reno_idrecord: null,
      reno_creationdate: null,
      reno_notificationdate: null,
      reno_comments: null,
      reno_idstatus: null,
      reno_user: null,
    },
    rules: {
      email: (v) => (v || "").match(/@/) || "Please enter a valid email",
      length: (len) => (v) =>
        (v || "").length <= len || "Ha superado el máximo permitido",
      password: (v) =>
        (v || "").match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
        "Password must contain an upper case letter, a numeric character, and a special character",
      required: (v) => !!v || "Este campo es requerido",
    },
  }),
  methods: {
    ...mapActions("comun", ["setSnackBar"]),
    ...mapActions("records", ["setRecordNotiNew", "setRecordNotiUpdate"]),
    ...mapActions("avisos", ["setAvisosPasadosUsuario"]),
    async guardarNoti() {
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }

      if (this.editando === true) {
        await this.setRecordNotiUpdate(this.seg);
        await this.setAvisosPasadosUsuario(this.gtrUserData.id);
        this.$emit("emitCancelar");
      } else {
        if (!this.seg.reno_notificationdate) {
          const miSnack = {
            mostrar: true,
            texto: "Es obligatorio seleccionar una fecha para crear un aviso",
            color: "warning",
          };
          this.setSnackBar(miSnack);
          return;
        }
        this.seg.reno_user = this.gtrUserData.id;
        await this.setRecordNotiNew(this.seg);
        await this.setAvisosPasadosUsuario(this.gtrUserData.id);
        this.$emit("emitCancelar");
      }
    },
  },
  computed: {
    ...mapGetters("user", ["gtrUserData"]),
    fechaFormateada() {
      return moment(this.seg.reno_notificationdate).format("DD/MM/YYYY");
    },

    colorSelected() {
      const tmp = this.seg.reno_notificationdate;
      return !tmp ? "grey" : "success";
    },

    fechaAviso: {
      //NOTA: para manejar la fecha con moment en el formato adecuado
      get: function() {
        // console.log(this.seg.reno_notificationdate);
        const fecha = moment(this.seg.reno_notificationdate, "DD/MM/YYYY");
        if (fecha.isValid()) {
          return fecha.format("YYYY-MM-DD");
        } else {
          return null;
        }
      },
      set: function(newValue) {
        this.seg.reno_notificationdate = moment(newValue).format("DD/MM/YYYY");
      },
    },
  },

  mounted() {
    if (this.seguimiento !== null) {
      // console.log("editando");
      this.editando = true;
      this.seg = cloneDeep(this.seguimiento);
    } else {
      // console.log("nuevo");
    }
    this.seg.reno_idrecord = this.reco_id;
    // console.log(this.seg);
    this.isLoading = false;
  },
  /* al destruir el componente dejamos los valores por defecto */
  destroyed() {
    // console.log("destruido");
    this.isLoading = true;
    this.editando = false;
    this.seg.reno_notificationdate = null;
    this.seg = null;
    this.seg = {
      reno_id: null,
      reno_idrecord: null,
      reno_creationdate: null,
      reno_notificationdate: null,
      reno_comments: null,
      reno_idstatus: null,
      reno_user: null,
    };
  },
};
</script>

<style scoped></style>
