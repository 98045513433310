<template>
  <v-container fluid v-if="!isLoading">
    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
          :rules="[rules.required]"
          dense
          label="Nombre Comercial"
          outlined
          v-model="misDatos.reco_name"
          autocomplete="new"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          :counter="10"
          :rules="[v => requeridoConAlta(v, 'cif')]"
          dense
          label="CIF"
          outlined
          autocomplete="new"
          v-model="misDatos.reco_cif"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :rules="[v => requeridoConAlta(v, 'email')]"
          dense
          label="E-mail"
          outlined
          autocomplete="new"
          v-model="misDatos.reco_email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            :items="estado"
            dense
            item-text="stat_name"
            item-value="stat_id"
            label="Estado"
            outlined
            required
            v-model="misDatos.reco_idstatus"
            :menu-props="{ maxHeight: 'auto' }"
            @change="$emit('emitValidate')"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
            dense
            label="Nombre Fiscal"
            outlined
            v-model="misDatos.reco_namefis"
            autocomplete="new"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          disabled
          label="Fecha de Alta"
          outlined
          type="date"
          v-model="misDatos.reco_creationdate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          disabled
          label="Última modificación"
          outlined
          type="date"
          v-model="misDatos.reco_updatingdate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          label="Alta Contrato"
          outlined
          type="date"
          v-model="misDatos.reco_contractdate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          label="Fin Contrato"
          outlined
          type="date"
          v-model="misDatos.reco_notificationdate"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="2">
        <v-select
          :items="tipoCentro"
          :rules="[rules.required]"
          dense
          item-text="tipo"
          item-value="id"
          label="Tipo de Centro"
          outlined
          v-model="misDatos.reco_idticen"
        ></v-select>
      </v-col>
      <v-col class="" cols="12" md="2" v-if="misDatos.reco_idticen === 3">
        <div style="width:100%" class="text-center">
          <v-switch
            class="mx-4 mt-1"
            v-model="misDatos.reco_agrup"
            inset
            color="red"
            label="Agrupar Comentarios en Guía"
          ></v-switch>
        </div>
      </v-col>

      <v-col cols="12" :md="misDatos.reco_idticen === 3 ? 8 : 10">
        <v-text-field
          autocomplete="new"
          dense
          label="Archivo"
          outlined
          counter="200"
          hint="Máximo 200 caracteres"
          v-model="misDatos.reco_archivo"
          :maxlength="200"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
            autocomplete="new"
            dense
            label="Observaciones"
            outlined
            counter="250"
            hint="Máximo 250 caracteres"
            v-model="misDatos.reco_observaciones"
            :maxlength="250"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-container class="pa-0 ma-0 px-1" fluid>
        <v-card outlined text tile>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-switch
                    class="ml-1 mt-0"
                    v-model="misDatos.reco_guiastipo"
                    inset
                    label="Seleccionar Guías"
                    @change="onChangeGuiasTipo"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  :items="tipoGuia"
                  v-model="compTiposGuia"
                  item-text="tipo"
                  item-value="id"
                  chips
                  dense
                  label="Guias"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  :disabled="!misDatos.reco_guiastipo"
                  :rules="misDatos.reco_guiastipo ? [rules.guiasTipo] : []"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="12">
        <v-card outlined text tile>
          <v-card-title class="pt-1">
            <h5>Representante</h5>
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  label="Representante"
                  outlined
                  v-model="misDatos.reco_agentname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  dense
                  label="DNI Representante"
                  outlined
                  v-model="misDatos.reco_agentdni"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- botones de guardar y clonar -->
    <v-row>
      <v-col class="pa-4 " cols="12" md="12">
        <v-btn
          @click="$emit('emitGuardarDatos')"
          class="mr-4 float-right"
          color="primary"
          >guardar
        </v-btn>
        <v-btn
          @click="volverPrincipal"
          class="mr-4 float-right"
          color="warning"
          outlined
          >Cancelar
        </v-btn>
        <v-btn
          @click="$emit('emitClonarFicha')"
          class="ml-4 float-left"
          color="orange white--text"
          >clonar registro
        </v-btn>
      </v-col>
    </v-row>

    <!--     <code>
      {{ misDatos }}
    </code> -->
  </v-container>
</template>

<script>
// import { mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "ficha-detalle-general",
  props: ["datos", "estado", "tipoCentro", "tipoGuia", "volverPrincipal"],
  data: () => ({
    isLoading: true,
    misDatos: null,
    codPostal: null,
    entries: [],
    codPostalLoading: false,
    rules: {
      required: value => !!value || "Requerido.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /.+@.+\..+/;
        return pattern.test(value) || "e-mail no válido.";
      },
      minChars: value => (value && value.length >= 8) || "Mínimo 8 caracteres",
      guiasTipo: value => (value && value.length > 0) || "Debe seleccionar al menos un tipo"
    }
  }),

  computed: {
    compTiposGuia: {
      get() {
        const tmp = this.misDatos.reco_idguiastipo;
        if (!tmp) {
          return [];
        }
        let salida = tmp.split(",").map(s => {
          if (!isNaN(parseInt(s))) {
            return parseInt(s);
          }
        });
        return salida;
      },
      set(valor) {
        this.misDatos.reco_idguiastipo = valor.join(",") || "";
      }
    },

    items() {
      return this.entries.map(entry => {
        const salida =
          entry.zico_code +
          " - " +
          entry.zico_town +
          " (" +
          entry.zico_province +
          ")";
        return Object.assign({}, entry, { salida });
      });
    }
  },

  watch: {
    misDatos: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== null) {
          this.$emit("emitActualizarDatos", newValue);
        }
      }
    },
    datos: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        //Lo único que necesita actualizarse al venir de otra pestaña es la fecha de actualización
        //En caso contrario podría hacer un this.misDatos = cloneDeep(this.datos);
        if(!!newValue && !!oldValue && newValue.reco_updatingdate!=oldValue.reco_updatingdate)
          this.misDatos.reco_updatingdate=newValue.reco_updatingdate;
      }
    }
  },

  methods: {
    /* funcion para generar la validacion dinámica en base al estado*/
    requeridoConAlta(val, t) {
      // si tenemos estado = alta
      if (this.misDatos.reco_idstatus === 6) {
        switch (t) {
          case "email": {
            if (val) {
              const pattern = /.+@.+\..+/;
              return pattern.test(val) || "e-mail no válido.";
            }
            else
              return true;
          }
          case "cif": {
            if (val)
              return (val && val.length >= 8) || "Mínimo 8 caracteres";
            else
              return true;
          }
        }
      } else if (val) {
        // si no es necesario alta, validamos que los datos sean correctos
        switch (t) {
          case "email": {
            const pattern = /.+@.+\..+/;
            return pattern.test(val) || "e-mail no válido.";
          }
          case "cif": {
            return (val && val.length >= 8) || "Mínimo 8 caracteres";
          }
        }
      } else {
        return true;
      }
    },
    onChangeGuiasTipo(tipo){
      if(!tipo)
        this.misDatos.reco_idguiastipo=null;
    },
  },
  mounted() {
    this.misDatos = cloneDeep(this.datos);
    this.isLoading = false;
  }
};
</script>

<style scoped>
.v-input {
  font-size: 0.9em;
}
/deep/.v-list-item__mask {
  background-color: yellow !important;
  color: red !important;
  font-size: 1.3em;
  font-weight: bold;
}
</style>
