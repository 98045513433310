<template>
  <v-alert
    :type="type"
    :dismissible="dismissible"
    transition="scale-transition"
  >
    <template v-slot:close>
      <v-btn icon dark>
        <v-icon @click="cerrar()">mdi-close</v-icon>
      </v-btn>
    </template>
    <v-row dense>
      <v-col class="grow">
        {{ texto }}
      </v-col>
      <v-col class="shrink">
        <v-btn
          class="white--text mr-4" 
          outlined         
          x-small
          @click="irNuevoRegistro()"
          >Ir al nuevoregistro</v-btn
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "alert-msg",
  props: ["texto", "type", "dismissible", "newId"],
  methods: {
    cerrar() {     
      this.$emit("dismiss");
    },
    irNuevoRegistro() {
        //ponemos la url a la nueva id
        this.$router.push({ name: 'fdetalle', params: { id: this.newId } });       
        this.$emit('irNuevoRegistro', { id: this.newId });
    }
  },
};
</script>

<style lang="scss" scoped></style>
