var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.mostrarFormDireccion)?_c('v-tooltip',{attrs:{"left":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"float-right mr-1",attrs:{"small":"","color":"primary","fab":""},on:{"click":function($event){return _vm.crearDireccion()}}},on),[_c('v-icon',[_vm._v("mdi-home-plus-outline")])],1)]}}],null,false,1009816570)},[_c('span',[_vm._v("Crear Dirección")])]):_c('v-tooltip',{attrs:{"left":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"float-right mr-1",attrs:{"x-large":"","icon":"","color":"red"},on:{"click":function($event){_vm.mostrarFormDireccion = false}}},on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}])},[_c('span',[_vm._v("Cancelar")])])],1)],1),_c('v-expand-transition',[(_vm.mostrarFormDireccion)?_c('ficha-direc-form',{attrs:{"propDireccion":_vm.direccionEditar,"reco_id":_vm.reco_id},on:{"emitCancelar":function($event){_vm.mostrarFormDireccion = false}}}):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.misDirecciones.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tabla.headers,"items":_vm.valores,"hide-default-footer":_vm.valores.length < 15,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [15, 30, 50, 100],
          'items-per-page-text': 'Filas por página'
        }},scopedSlots:_vm._u([{key:`item.read_idcp`,fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.zipdata.zico_code)+" - "+_vm._s(item.zipdata.zico_town)+" ("+_vm._s(item.zipdata.zico_province)+") ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.editarDireccion(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.eliminaDir(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)}):_vm._e()],1)],1),_c('dialog-eliminar',{attrs:{"mostrar":_vm.dialogEliminar.mostrar,"cabecera":_vm.dialogEliminar.cabecera,"cuerpo":_vm.dialogEliminar.cuerpo},on:{"emitAceptar":_vm.eliminarDireccion,"emitCancelar":function($event){_vm.dialogEliminar.mostrar = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }