<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
                v-model="filtro.fecha_creacion_ini"
                label="Fecha Creación (desde)"
                dense
                outlined
                type="date"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
                v-model="filtro.fecha_creacion_fin"
                label="Fecha Creación (hasta)"
                dense
                outlined
                type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
                v-model="filtro.reno_user"
                label="Usuario"
                :items="usuarios"
                item-value="name"
                item-text="name"
                dense
                outlined
                :menu-props="{ closeOnContentClick: true }"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="filtro.usuario = null">
                  <v-list-item-content>
                    <v-list-item-title>TODOS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
                v-model="filtro.reno_comments"
                label="Comentarios"
                dense
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
                v-model="filtro.fecha_aviso_ini"
                type="date"
                label="Fecha Aviso (desde)"
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
                v-model="filtro.fecha_aviso_fin"
                type="date"
                label="Fecha Aviso (hasta)"
                dense
                outlined
                class="text-center"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-tooltip top color="orange">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="float-right ml-1"
                    color="orange"
                    @click="mostrarForm = !mostrarForm"
                    dark
                    v-on="on"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo aviso</span>
            </v-tooltip>
            <v-tooltip top color="blue">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="float-right ml-1"
                    color="blue"
                    @click="refrescarDatos"
                    dark
                    v-on="on"
                >
                  <v-icon dark>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <span>Actualizar datos</span>
            </v-tooltip>
            <v-tooltip left color="purple">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="float-right"
                    color="purple"
                    @click="resetFiltros"
                    dark
                    v-on="on"
                >
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Limpiar formulario</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <ficha-segu-form
              :reco_id="datos.reco_id"
              :seguimiento="editFormData"
              @emitCancelar="refrescarTabla"
              v-if="mostrarForm"
          ></ficha-segu-form>
        </v-expand-transition>
        <v-row class="mt-5" v-if="avisos.length > 0">
          <v-col class="py-0 my-0" cols="12">
            <v-data-table
                dense
                fixed-header
                v-if="avisos !== null"
                multi-sort
                :headers="headers"
                :items="avisos"
                locale="es-es"
                loading-text="Cargando... Por favor espere"
                :items-per-page="10"
                :mobile-breakpoint="870"
                :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [15, 30, 50, 100],
            'items-per-page-text': 'Filas por página'
          }"
            >
              <template v-slot:item.reno_user="{ item }">
                <v-chip
                    small
                    class="white--text"
                    color="blue"
                    label
                    v-show="item.reno_user"
                >
                  {{ item.reno_user }}
                </v-chip>
              </template>
              <template v-slot:item.reno_creationdate_sp="{ item }">
                <v-chip small class="white--text" color="green" label>
                  {{ item.reno_creationdate_sp }}
                </v-chip>
              </template>
              <template v-slot:item.reno_notificationdate_sp="{ item }">
                <v-chip
                    small
                    class="white--text"
                    color="orange"
                    label
                    v-show="item.reno_notificationdate_sp"
                >
                  {{ item.reno_notificationdate_sp }}
                </v-chip>
              </template>
              <template v-slot:item.reno_comments_html="{ item }">
                <div
                    class="text-leftbody-2"
                    v-html="item.reno_comments_html"
                ></div>
              </template>
              <template v-slot:item.acciones="{ item }">
                <v-tooltip color="red" top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        @click="quieresEliminar(item.reno_id)"
                        class="mr-2 float-right"
                        small
                        v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
                <v-tooltip color="success" top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        @click="editarAviso(item)"
                        class="mr-2 float-right"
                        color="red"
                        small
                        v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip color="primary" left v-if="!!item.reno_notificationdate">
                  <template v-slot:activator="{ on }">
                    <v-icon
                        @click="eliminarFechaAviso(item)"
                        class="mr-2 float-right"
                        color="primary"
                        small
                        v-on="on"
                    >
                      mdi-bell-off
                    </v-icon>
                  </template>
                  <span>Eliminar Fecha Aviso</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- si no hay aviso mostramos un alert -->
        <v-row class="mt-3" v-else>
          <v-col cols="12">
            <v-alert type="info"> No hay avisos creados..... </v-alert>
          </v-col>
        </v-row>
        <!-- dialogo eliminar -->
        <dialog-eliminar
            :cabecera="dialogEliminar.cabecera"
            :cuerpo="dialogEliminar.cuerpo"
            :mostrar="dialogEliminar.mostrar"
            @emitAceptar="eliminarAviso"
            @emitCancelar="dialogEliminar.mostrar = false"
        ></dialog-eliminar>
      </v-list-item-content>
    </v-list-item>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import {mapActions, mapGetters, mapState} from "vuex";
import { cloneDeep } from "lodash";
import DialogEliminar from "@/components/comun/Dialog";
import FichaSeguForm from "@/components/fichas/parts/forms/FichaSeguForm";

export default {
  name: "ficha-detalle-seguimiento",
  props: ["datos"],
  components: {
    FichaSeguForm,
    DialogEliminar,
  },
  data: () => ({
    mostrarForm: false,
    editFormData: null,
    dialogEliminar: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
    headers: [
      {
        text: "Usuario",
        align: "left",
        value: "reno_user",
      },
      {
        text: "Fecha Alta",
        align: "left",
        value: "reno_creationdate_sp",
      },
      {
        text: "Fecha Aviso",
        align: "left",
        value: "reno_notificationdate_sp",
      },
      {
        text: "Comentarios",
        align: "left",
        value: "reno_comments_html",
      },
      {
        text: "Acciones",
        align: "right",
        value: "acciones"
      }
    ],
    datosTratados: null,
    filtro: {
      fecha_creacion_ini: null,
      fecha_creacion_fin: null,
      fecha_aviso_ini: null,
      fecha_aviso_fin: null,
      reno_comments: null,
      reno_user: null,
    },
  }),
  computed: {
    ...mapGetters("user", ["gtrUserData"]),
    ...mapState("avisos", ["usuarios"]),
    avisos() {
      if (this.datosTratados === null)
        return [];

      let tmpFiltro = {};
      Object.keys(this.filtro).forEach((e) => {
        if (
            !!this.filtro[e] &&
            (this.filtro[e].length > 0 || Number.isInteger(this.filtro[e]))
        ) {
          const tKey = e;
          tmpFiltro[tKey] = this.filtro[e];
        }
      });

      return filtros(this.datosTratados, tmpFiltro);
    },

    listadoAvisos() {
      if (this.datos.record_notifications.length === 0) {
        return [];
      }
      return this.datos.record_notifications;
    },
  },

  methods: {
    ...mapActions("records", ["setRecordNotiDelete", "setRecordNotiUpdate"]),
    ...mapActions("avisos", ["setAvisosPasadosUsuario", "setUsuarios"]),
    editarAviso(item) {
      // let data = cloneDeep(item);

      this.editFormData = item;
      this.mostrarForm = true;
    },
    async eliminarFechaAviso(aviso) {
      aviso.reno_notificationdate = null;
      aviso.reno_notificationdate_sp = null;
      await this.setRecordNotiUpdate(aviso).then((res) => {
        // console.log(res);
      });
      await this.setAvisosPasadosUsuario(this.gtrUserData.id);
    },

    quieresEliminar(id) {
      this.dialogEliminar.cabecera = "AVISO";
      this.dialogEliminar.cuerpo =
        "Desea eliminar este registro?<br/>Esta operación no se podrá revertir";
      this.dialogEliminar.id = id;
      this.dialogEliminar.mostrar = true;
    },

    async eliminarAviso() {
      //console.log("has pulsado eliminar");
      this.dialogEliminar.mostrar = false;
      await this.setRecordNotiDelete(this.dialogEliminar.id).then((res) => {
        // console.log(res);
        this.dialogEliminar.mostrar = false;
        this.dialogEliminar = {
          mostrar: false,
          cabecera: null,
          cuerpo: null,
          id: null,
        };
      });
      await this.setAvisosPasadosUsuario(this.gtrUserData.id);
      this.refrescarTabla();
    },

    cargarUsuarios(){
      this.setUsuarios();
    },
    refrescarDatos() {
      this.resetFiltros();
      this.datosTratados = this.generarDatosTratados();
    },
    resetFiltros() {
      Object.keys(this.filtro).forEach((e) => (this.filtro[e] = null));
    },
    generarDatosTratados() {
      const datos = this.datos.record_notifications;
      if (!datos) {
        return [];
      }
      const salida = datos.reduce((acc, curr) => {
        const notdate = moment(curr.reno_notificationdate).isValid()
            ? moment(curr.reno_notificationdate).format("DD/MM/YYYY")
            : null;

        acc.push({
          reno_id: curr.reno_id,
          reno_idrecord: curr.reno_idrecord,
          reno_creationdate_sp: moment(curr.reno_creationdate).format(
              "DD/MM/YYYY"
          ),
          reno_notificationdate_sp: notdate,
          reno_creationdate: curr.reno_creationdate,
          reno_notificationdate: curr.reno_notificationdate,
          reno_comments: curr.reno_comments,
          reno_comments_html: curr.reno_comments
              .trim()
              .replaceAll("\n", "<br/> "),
          reno_idstatus: curr.reno_idstatus,
          reno_user: curr.reno_user ? curr.user.name : null,
          reno_user_id: curr.reno_user,
        });
        return acc;
      }, []);
      return salida;
    },
    refrescarTabla(){
      this.mostrarForm = false;
      this.refrescarDatos();
    }
  },
  mounted() {
    this.cargarUsuarios();
    this.refrescarDatos();
  },

  watch: {
    mostrarForm(newValue, oldValue) {
      if (newValue === false) {
        this.editFormData = null;
      }
    },
  },
};

/* funcion para filtrar los datos */
const filtros = (data, _filtros) => {
  var filterKeys = Object.keys(_filtros);
  // console.log(filterKeys, filtros);
  const salida = data.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      //comprobamos que el filtro actual no esté vacio
      if (!_filtros[eachKey].length && !Number.isInteger(_filtros[eachKey])) {
        return true;
      }

      if (eachKey === "reno_user") {
        return eachObj[eachKey] === _filtros[eachKey];
      }

      //comprobamos que el filtro actual sea o no de fecha

      if (eachKey.includes("fecha_creacion")) {
        return compararFechas(
            _filtros.fecha_creacion_ini,
            _filtros.fecha_creacion_fin || null,
            eachObj.reno_creationdate
        );
      }

      if (eachKey.includes("fecha_aviso")) {
        return compararFechas(
            _filtros.fecha_aviso_ini,
            _filtros.fecha_aviso_fin || null,
            eachObj.reno_notificationdate
        );
      }
      //comparamos un texto cualquiera
      if (compararRegexStr(_filtros[eachKey], eachObj[eachKey])) {
        return true;
      }
    });
  });
  return salida;
};

/* funcion para comparar via regex los strings */
const compararRegexStr = (cadena, texto) => {
  //la cadena es lo que escribo en los inputs
  let pattern = texto.toLowerCase();

  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  //escapamos cualquier caracter especial que estemos buscando
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};

/* funcion para comparar fechas */
const compararFechas = (fechaIni, fechaFin, fechaControl) => {
  //si la fecha de inicio no está seteada devolvemos true y no comparamos
  if (!fechaIni) {
    return true;
  }

  //si no hay fecha fin comparamos las fechas mayor e igula que FechaControl
  if (!fechaFin) {
    const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
    const mFechaControl = moment(fechaControl, "YYYY-MM-DD");
    return mFechaControl.isSameOrAfter(mFechaIni);
  }
  //si está seteada tbn la fecha de fin
  const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
  const mFechaControl = moment(fechaControl, "YYYY-MM-DD");
  const mFechaFin = moment(fechaFin).add(1, "d").format("YYYY-MM-DD");

  return (
      mFechaControl.isSameOrAfter(mFechaIni) && mFechaControl.isBefore(mFechaFin)
  );
};

const dialogReset = () => {
  return {
    mostrar: false,
    cabecera: null,
    cuerpo: null,
    id: null,
  };
};
</script>

<style scoped>
::v-deep.v-data-table td {
  font-size: 0.8em;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.just-line-break {
  white-space: pre-line;
  text-overflow: ellipsis;
}

.line-break-and-tab {
  white-space: pre-wrap;
}
</style>
