<template>
  <v-container fluid v-if="!isLoading">
    <v-form ref="form" v-model="validForm">
      <v-row dense>
        <v-col cols="12" md="12">
          <v-card flat outlined tile class="elevation-5">
            <v-card-title class="mb-0 pb-0">
              <v-row dense>
                <v-col cols="12" md="4">
                  <div class="headline float-left">
                    {{ tituloCabecera() }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text--primary">
              <!-- form cate multiple -->
              <ficha-esp-multi
                v-if="!esp.resp_idcategory && !esp.resp_idsubcategory"
                :compRules="compRules"
                :compCategorias="compCategorias"
                :subcategorias="subcategorias"
                :nuevo="nuevo"
                @emitCategoriasMulti="categoriasMultiSet"
                @emitBloquearForm="bloquearForm"
              ></ficha-esp-multi>

              <v-row v-else>
                <v-col cols="12" md="6">
                  <v-select
                    :items="compCategorias"
                    item-text="cate_name"
                    item-value="cate_id"
                    v-model="esp.resp_idcategory"
                    label="Categoría"
                    :disabled="nuevaSubCat"
                    clearable
                    :rules="[compRules.requiredCategoria]"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    :items="compSubcategorias"
                    v-model="esp.resp_idsubcategory"
                    item-value="subc_id"
                    item-text="subc_name"
                    label="Especialidad"
                    :disabled="compSubcategorias.length === 0 || nuevaSubCat"
                    :rules="[compRules.requiredCategoria]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="12">
                  <!-- PASO POR EL PROP SELECTED TODOS LOS DATOS RELATIVOS A LA DIRECCION -->
                  <v-textarea
                    rows="3"
                    v-model="esp.resp_comments"
                    label="Comentarios"
                    outlined
                    dense
                    autocomplete="new"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12"
                  ><v-btn
                    :disabled="formBloqueado"
                    class="float-right mx-1"
                    :outlined="!formBloqueado"
                    small
                    color="primary"
                    dark
                    required
                    @click="guardarDatos"
                    >Guardar</v-btn
                  >
                  <v-btn
                    class="float-right mx-1"
                    outlined
                    small
                    color="red"
                    dark
                    @click="$emit('emitCancelar')"
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { cloneDeep, orderBy } from "lodash";
import { mapActions, mapMutations } from "vuex";

import FichaEspMulti from "./FichaEspMulti";

export default {
  name: "Especialidades-form",
  components: {
    FichaEspMulti,
  },
  props: [
    "datos",
    "categorias",
    "subcategorias",
    "reco_id",
    "listaEspecialidadesMulti",
  ],
  data: () => ({
    formBloqueado: false,
    multiComentario: [],
    multiEspecialidades: false,
    multiAgrupar: false,
    multiEspSel: [],
    validForm: true,
    nuevo: false,
    nuevaSubCat: false,
    isLoading: true,
    esp: {
      resp_id: null,
      resp_idrecord: null,
      resp_idcategory: null,
      resp_idsubcategory: null,
      resp_comments: '',
      resp_date: null,
      resp_idstatus: null, // a 0 los eliminados??
    },
    rules: {
      requiredCategoria: (value) => {
        if (value) {
          return "Requerido";
        }
        // return (!!value && this.multiEspecialidades) || "Requerido.";
      },
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern = /.+@.+\..+/;
        return pattern.test(value) || "e-mail no válido.";
      },
      minChars: (value) =>
        (value && value.length >= 8) || "Mínimo 8 caracteres",
    },
  }),
  methods: {
    ...mapActions("records", [
      "setSpecialtiesUpdate",
      "setSpecialtiesNew",
      "setCrearComMultiple",
    ]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),
    /* funcion para bloquear el form desde el hijo */
    bloquearForm(evt) {
      // console.log("bloquear", evt);
      this.formBloqueado = evt;
    },
    /* funcion para setear la variable multi del form */
    categoriasMultiSet(data) {
      this.multiAgrupar = data.agrupar;
      this.multiComentario = [...data.cats];
      // console.log(this.multiComentario);
    },

    /* funcion para crear un titulo */
    tituloCabecera() {
      if (this.nuevaSubCat === true) {
        return "Nuevo Comentario";
      } else if (this.nuevo === false) {
        return "Editando Comentario";
      } else {
        return "Nuevo Comentario";
      }
    },
    /* funcion para guardar datos */
    async guardarDatos() {
      // validamos el form
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }

      // multiples Comentarios
      if (this.multiComentario.length > 0) {
        // console.log("hay multiples comentarios");
        const datos = {
          reco_id: this.reco_id,
          registros: this.multiComentario,
          comentario: this.esp.resp_comments,
          agrupar: this.multiAgrupar,
        };
        const res = await this.setCrearComMultiple(datos);
        this.SET_RECORDS_RELOAD(true);
        //  console.log(res);
        this.$emit("emitCancelar");
        return 1;
      }

      // comprobamos si queremos asignar un comentario a multiples especialidades (subcategorias)
      // por ahora sin uso
      if (this.multiEspecialidades) {
        //preparamos los datos
        const data = {
          resp_comments: this.esp.resp_comments.trim(),
          especialidades: [...this.multiEspSel],
          reco_id: this.reco_id,
          agrupar: this.multiAgrupar ? 1 : 0,
        };
        //console.log(data);

        try {
          await this.setCrearComMultiple(data);
          this.SET_RECORDS_RELOAD(true);
          this.$emit("emitCancelar");
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
        return;
      }

      //guardamos un registro o editamos
      if (this.nuevo === true) {
        //agregamos un registro nuevo, bien sea cat+subcat o subcat de una cat ya existente
        this.esp.resp_idrecord = this.reco_id;
        this.setSpecialtiesNew(this.esp).then((res) => {
          //console.log(res);
          this.SET_RECORDS_RELOAD(true);
          this.$emit("emitCancelar");
        });
      } else {
        //editamos un registro existente
        console.log(this.esp);
        this.setSpecialtiesUpdate(this.esp).then((res) => {
          this.SET_RECORDS_RELOAD(true);
          this.$emit("emitCancelar");
        });
      }
    },
  },
  computed: {
    /* propiedad computada de validaciones */
    compRules() {
      return {
        requiredCategoria: (value) => {
          if (!value && !this.multiEspecialidades) {
            return "Requerido";
          }
          return true;
        },
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /.+@.+\..+/;
          return pattern.test(value) || "e-mail no válido.";
        },
        minChars: (value) =>
          (value && value.length >= 8) || "Mínimo 8 caracteres",
      };
    },

    /* propiedad computada con las categorias */
    compCategorias() {
      if (this.categorias.length === 0) {
        return [];
      }
      return orderBy(this.categorias, ["cate_name"], ["asc"]);
    },
    /* propiedad computada con las subcategorias */
    compSubcategorias() {
      const catid = this.esp.resp_idcategory;
      if (catid === null) {
        return [];
      }
      return orderBy(
        this.subcategorias.filter((s) => s.subc_idcategory == catid),
        ["subc_name"],
        ["asc"]
      );
    },
  },
  mounted() {
    // nuevo o edición
    if (this.datos !== null) {
      if (this.datos.nuevaSubesp === true) {
        this.nuevo = true;
        this.nuevaSubCat = true;
        this.esp.resp_idcategory = cloneDeep(this.datos.resp_idcategory);
        this.esp.resp_idsubcategory = cloneDeep(this.datos.resp_idsubcategory);
      } else {
        this.nuevo = false;
        this.esp = cloneDeep(this.datos);
      }
    } else {
      this.nuevo = true;
      // console.log("nuevo registro");
    }
    this.isLoading = false;
  },
};
</script>

<style scoped>
>>> .v-list-item__mask {
  background-color: yellow !important;
  color: blue !important;
  font-size: 1.2em;
}
</style>
