<template>
  <div>
    <v-row dense>
      <v-col cols="11" md="6">
        <v-combobox
            outlined
            dense
            :items="compCategorias"
            item-text="cate_name"
            v-model="resp_idcategory"
            label="Categoría"
            :disabled="nuevaSubcategoria"
            clearable
            :rules="[compRules.requiredCategoria]"
            @change="cambioCatEsp"
            @click:clear="resp_idsubcategory = null"
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <v-row dense>
          <v-col cols="11" :md="index > 0 ? 11 : 12" class="py-0 my-0">
            <v-combobox
                outlined
                dense
                :items="compSubcategorias"
                v-model="resp_idsubcategory"
                item-text="subc_name"
                label="Especialidad"
                :rules="[compRules.requiredCategoria]"
                no-data-text="Seleccione una Categoría primero"
                @change="cambioCatEsp"
            ></v-combobox>
          </v-col>
          <v-col cols="1" v-if="index > 0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  class="float-right mx-0"
                  x-small
                  color="red"
                  fab
                  dark
                  @click="cancelar"
                >
                  <v-icon>mdi-minus-thick</v-icon>
                </v-btn>
              </template>
              <span>Eliminar esta Categoría/Especialidad</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-alert dense type="error" v-if="mostrarError.includes(Number(index))"
      >Ya existe esta <b>categoría</b> y <b>especialidad</b>, por favor elija
      otra distinta, no podrá continuar hasta que no modifique el
      contenido</v-alert
    >
    <hr class="d-none d-sm-flex mb-3" />
  </div>
</template>

<script>
import { orderBy } from "lodash";
export default {
  name: "ficha-esp-cat-comp",
  props: [
    "data",
    "compRules",
    "compCategorias",
    "subcategorias",
    "nuevaSubCat",
    "index",
    "mostrarError"
  ],
  data: () => ({
    resp_idcategory: null,
    resp_idsubcategory: null,
    nuevaSubcategoria: false
  }),
  methods: {
    cancelar() {
      this.$emit("emitEliminar", this.index);
    },
    cambioCatEsp() {
      const tmp = {
        id: this.index,
        resp_idcategory: (!!this.resp_idcategory ? this.resp_idcategory.cate_id : null),
        resp_idsubcategory: (!!this.resp_idsubcategory ? this.resp_idsubcategory.subc_id : null)
      };
      this.$emit("emitCambioCat", tmp);
    }
  },
  computed: {
    /* propiedad computada con las subcategorias */
    compSubcategorias() {
      let catid = null;
      if(!!this.resp_idcategory)
        catid = this.resp_idcategory.cate_id;
      if (catid === null) {
        return [];
      }
      return orderBy(
        this.subcategorias.filter(s => s.subc_idcategory == catid),
        ["subc_name"],
        ["asc"]
      );
    }
  }
};
</script>

<style scoped>
>>> button.v-icon {
  color: red;
}
/deep/.v-list-item__mask {
  background-color: yellow !important;
  color: blue !important;
  font-size: 1.2em !important;
  text-decoration: underline !important;
}
</style>
