<template>
  <v-container fluid class="class-drag">
    <div class="drop-active" v-show="$refs.upload && $refs.upload.dropActive">
      <h3 class="text-center">Soltar Fichero</h3>
    </div>
    <!-- subida arrastrar -- se muestra al pulsar nuevo-->
    <div class="mt-0">
      <file-upload
        :thread="1"
        :drop="true"
        :drop-directory="false"
        :headers="compUploadComp.headers"
        :multiple="archivo==null"
        :post-action="compUploadComp.rutaApi"
        input-id="file"
        @input-file="inputFile"
        ref="upload"
        v-model="files"
        :add-index="true"
      >
      </file-upload>
      <v-card outlined>
        <h2 class="pa-2 text-center">{{ archivo!=null ? archivo.refi_name : 'Subir Nuevo fichero' }}</h2>
        <v-divider></v-divider>
        <v-row class="mt-0" v-if="files.length === 0">
          <v-col cols="12">
            <div
              class="class-drag headline text-center py-2 mb-2 grey--text"
              @click="triggerClick()"
            >
              <b class="hoverMe">
                Arrastrar un archivo o hacer click
              </b>
            </div>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-card-text>
          <!-- DATOS DEL FICHERO -->
          <v-card outlined>
            <v-row v-if="files.length > 0">
              <v-col class="py-0" cols="12">
                <v-card flat>
                  <v-simple-table class="v-btn--flat" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Nombre</th>
                          <th class="text-left">Tamaño</th>
                          <th class="text-left">Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="i" v-for="(file, i) in files">
                          <td>{{ file.name }}</td>
                          <td>{{ file.size | formatSize }}</td>
                          <td>
                            <div class="float-right">
                              <v-progress-circular
                                :value="file.progress"
                                class="my-1"
                                color="light-blue"
                                rotate="0"
                                size.number="30"
                                width.number="38"
                                >{{ parseInt(file.progress) }}
                              </v-progress-circular>
                              <v-btn
                                @click="$refs.upload.remove(file)"
                                class="px-1"
                                color="red"
                                icon
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="files.length > 0 || archivo!=null" class="mx-2 mt-2" dense>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :rules="[v => !!v || 'El comentario Requerido']"
                  label="Agregar un comentario (requerido)"
                  required
                  v-model="comment"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!comment">
                <v-fade-transition>
                  <v-alert type="info" dense outlined color="red"
                    >Para subir el archivo es obligatorio añadir un
                    comentario</v-alert
                  >
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col md="6">
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                @click="triggerClick()"
                class="white--text"
                color="primary"
                v-if="files.length === 0"
              >
                <v-icon color="white">mdi-plus-circle-outline</v-icon>
                Seleccionar Archivo(s)
              </v-btn>
              <!-- BOTON LIMPIAR -->
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                @click="$refs.upload.clear()"
                class="ml-2 white--text d-block"
                color="orange"
                v-else
              >
                <v-icon>mdi-stop-circle-outline</v-icon>
                Limpiar
              </v-btn>
            </v-col>
            <v-col md="6">
              <!-- BOTON GUARDAR -->
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="estadoBotonGuardar"
                @click="guardarAdjunto()"
                class="white--text float-right"
                color="green"
                >Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { config } from "@/_config";
import { recordsService } from "@/_services/";
import { mapActions, mapMutations } from "vuex";
import VueUploadComponent from "vue-upload-component";

export default {
  name: "ficha-upload-component",
  props: ["reco_id", "archivo"],
  components: {
    FileUpload: VueUploadComponent
  },
  data: () => ({
    files: [],
    comment: null,
    nFicheros: 0
  }),

  methods: {
    ...mapMutations("records", ["SET_RECORD_ADJ"]),
    ...mapActions("records", ["setDeleteAttachment"]),
    /* funcion para hacer trigger en el componente desde el botón seleccionar archivo*/
    triggerClick() {
      document.getElementById("file").click();
    },
    /* funcion al guardar el nuevo archivo */
    async guardarAdjunto() {
      if (this.comment === null) {
        return;
      }

      const currentData = {
        reco_id: this.reco_id,
        comment: this.comment
      };

      if(this.archivo!=null){
        //Si no hay adjunto solo actualizamos el comentario
        if(this.files.length === 0){
          let tmp = {
            comentario: this.comment
          };

          try {
            const response=await recordsService.recordFileUpdate(this.archivo['refi_id'], tmp);
            this.SET_RECORD_ADJ(response.data);
            this.$emit("createdFile", response.data);
          } catch (error) {
            console.log(error);
          }

        }
        //Eliminamos el adjunto y lo volvemos a subir
        else{
          const response = await this.setDeleteAttachment(this.archivo['refi_id']);

          this.$refs.upload.active = true;
          this.files.forEach(fichero => {
            fichero.data = currentData;
            this.$refs.upload.update(fichero, { active: true });
          });
        }
      }
      else{
        //si los datos son válidos procedemos a subir el archivo
        this.$refs.upload.active = true;
        this.files.forEach(fichero => {
          fichero.data = currentData;
          this.$refs.upload.update(fichero, { active: true });
        });
      }
    },
    /* funcion para controlar la respuesta de la subida de datos */
    inputFile(newFile, oldFile) {
      //Fichero añadido
      if (newFile && !oldFile) {
        this.nFicheros++;
      }
      if (newFile && oldFile) {
        //Fichero subido correctamente
        if(newFile.success!==oldFile.success) {
          if (newFile.xhr) {
            //  Get the response status code
            if (newFile.xhr.status === 200) {
              this.nFicheros--;
              if(this.nFicheros!==0)
                return;

              //Solo actualizamos cuando se han terminado todas las subidas
              this.SET_RECORD_ADJ(newFile.response.data);
              this.$emit("createdFile", newFile.response.data);
            }
          }
        }
      }
    }
  },

  computed: {
    /* objeto con los datos necesarios para poder realizar la subida */

    compUploadComp() {
      const token = JSON.parse(
        localStorage.getItem(process.env.VUE_APP_LOCALST)
      ).access_token;
      const rutaApi = `${config.apiUrl}/newAttachment`;
      const headers = {
        Authorization: "Bearer " + token
      };
      const data = { comment: this.comment, reco_id: this.reco_id };
      return {
        rutaApi,
        headers,
        data
      };
    },
    estadoBotonGuardar(){
      if(this.archivo!=null){
        return this.comment === null || this.comment === '';
      }
      else
        return this.files.length === 0 || this.comment === null || this.comment === '';
    }
  },

  filters: {
    /* formatear el tamaño del documento adjunto */
    formatSize: function(value) {
      const salida = value < 1024000 ? value / 1024 : value / 1024000;
      const tipo = value < 1024000 ? "Kb" : "Mb";
      return `${salida.toFixed(2)} ${tipo}`;
    }
  },
  mounted() {
    if(this.archivo!=null){
      this.comment=this.archivo['refi_comment'];
    }
    this.nFicheros=0;
  }
};
</script>

<style scoped>
.class-drag label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}

.class-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.class-drag .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.hoverMe:hover {
  color: red !important;
  cursor: pointer;
}
</style>
