<template>
  <v-container fluid>
    <v-card v-if="!loading">
      <v-card-title class="white--text light-blue darken-3" border="bottom">
        <h4 class="headline">
          <span class="title">{{ textoCabecera }}</span>
        </h4>
      </v-card-title>
      <!-- tabs -->
      <v-tabs
        color="primary"
        left
        class="mt-0"
        v-model="activeTab"
        background-color="blue lighten-5"
      >
        <v-tab v-if="cambiosSinGuardar">
          <v-badge color="orange">
            <template v-slot:badge>
              <v-tooltip right color="green">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-asterisk</v-icon>
                </template>
                <span>Cambios Pendientes de ser guardados</span>
              </v-tooltip>
            </template>
            General
          </v-badge>
        </v-tab>
        <v-tab v-else> General </v-tab>
        <v-tab>
          <v-badge :color="countDirecciones === 0 ? 'red' : 'green'">
            <template v-slot:badge>
              {{ countDirecciones }}
            </template>
            Direcciones
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge :color="countNotificaciones === 0 ? 'red' : 'green'">
            <template v-slot:badge>
              {{ countNotificaciones }}
            </template>
            Seguimiento
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge :color="countAdjuntos === 0 ? 'red' : 'green'">
            <template v-slot:badge>
              {{ countAdjuntos }}
            </template>
            Adjuntos
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge :color="compNumEsp === 0 ? 'red' : 'green'">
            <template v-slot:badge>
              {{ compNumEsp }}
            </template>
            Especialidades
          </v-badge>
        </v-tab>
        <!-- pestaña general -->
        <v-tab-item>
          <v-form ref="form" v-model="validForm">
            <ficha-detalle-gen
              :datos="datos"
              :estado="estado"
              :tipoCentro="tipoCentro"
              :tipoGuia="tipoGuia"
              :volverPrincipal="volverPrincipal"
              @emitActualizarDatos="actualizarDatos"
              @emitGuardarDatos="guardarDatos"
              @emitClonarFicha="dialogClonarFicha"
              @emitValidate="$refs.form.validate()"
            ></ficha-detalle-gen>
          </v-form>
        </v-tab-item>
        <!-- pestaña direcciones  -->
        <v-tab-item>
          <ficha-detalle-dir
            @emitActualizarDatos="cargarDatos"
            :propDirecciones="direcciones"
            :reco_id="id"
          ></ficha-detalle-dir>
        </v-tab-item>
        <!-- pestaña seguimiento -->
        <v-tab-item>
          <ficha-detalle-seg :datos="datos" :reco_id="id"></ficha-detalle-seg>
        </v-tab-item>
        <!-- pestaña adjuntos -->
        <v-tab-item>
          <ficha-detalle-adj
            :datos="datos.record_files"
            :reco_id="id"
            @emit-actualiza-adj="actualizaAdjuntos"
          ></ficha-detalle-adj>
        </v-tab-item>
        <!-- pestaña despecialidades -->
        <v-tab-item>
          <ficha-detalle-esp
            :reco_id="id"
            :datos="datos.record_specialties"
            :categorias="categorias"
            :subcategorias="subcategorias"
            :reco_agrup="datos.reco_agrup"
            @emitActualizarDatos="cargarDatos"
            @emitActualizarEsp="actualizarEsp"
          ></ficha-detalle-esp>
        </v-tab-item>
      </v-tabs>

      <!-- insertamos el componente alert para mostrar una alerta cuando se clona un registro -->
      <v-row dense>
        <v-col cols="12" class="px-5">
          <alert-msg
            v-if="alertMsg.mostrarAlert"
            :texto="alertMsg.text"
            :type="alertMsg.type"
            :newId="alertMsg.newId"
            :dismissible="alertMsg.dismissible"
            @dismiss="alertMsg.mostrarAlert = false"
            @irNuevoRegistro="irNuevoRegistro"
          ></alert-msg>
        </v-col>
      </v-row>
    </v-card>

    <v-fab-transition v-if="!loading">
      <v-tooltip bottom color="red">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            key="share"
            color="red"
            fab
            x-small
            dark
            top
            right
            class="v-btn--example"
            absolute
            @click="volverPrincipal"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Volver</span>
      </v-tooltip>
    </v-fab-transition>
    <!-- dialogo salir sin guardar -->
    <dialog-salir-sin-guardar
      :mostrar="dialogSalir.mostrar"
      @emitAceptar="volverPrincipal(true)"
      @emitCancelar="dialogSalir.mostrar = false"
      :cabecera="dialogSalir.cabecera"
      :cuerpo="dialogSalir.cuerpo"
      :maxWidth="450"
    ></dialog-salir-sin-guardar>
    <!-- dialogo clonar -->
    <dialog-clonar
      :mostrar="dialogClonar.mostrar"
      @emitAceptar="clonarFicha"
      @emitCancelar="dialogClonar.mostrar = false"
      :cabecera="dialogClonar.cabecera"
      :cuerpo="dialogClonar.cuerpo"
      :maxWidth="450"
    ></dialog-clonar>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";
import FichaDetalleGen from "@/components/fichas/parts/FichaDetalleGen";
import FichaDetalleDir from "@/components/fichas/parts/FichaDetalleDir";
import FichaDetalleSeg from "@/components/fichas/parts/FichaDetalleSeg";
import FichaDetalleAdj from "@/components/fichas/parts/FichaDetalleAdj";
import FichaDetalleEsp from "@/components/fichas/parts/FichaDetalleEsp";
import DialogClonar from "@/components/comun/Dialog";
import DialogSalirSinGuardar from "@/components/comun/Dialog";
import AlertMsg from "@/components/comun/AlertMsg";

export default {
  name: "fichas-detalle",
  metaInfo: {
    title: 'Fichas'
  },
  components: {
    FichaDetalleGen,
    FichaDetalleSeg,
    FichaDetalleAdj,
    FichaDetalleEsp,
    FichaDetalleDir,
    DialogClonar,
    AlertMsg,
    DialogSalirSinGuardar,
  },
  data: () => ({
    datosActualizados: false,
    activeTab: 0,
    validForm: true,
    cambiosSinGuardar: false,
    loading: true,
    id: null,
    editando: false,
    categorias: null,
    subcategorias: null,
    direcciones: null,
    estado: null,
    tipoCentro: null,
    tipoGuia: [],
    datos: {
      reco_name: null,
      reco_phone: null,
      reco_cif: null,
      reco_email: null,
      reco_address: null,
      reco_idstatus: null,
      reco_idcp: null,
      reco_idticen: null,
      reco_agentname: null,
      reco_agentdni: null,
      reco_archivo: null,
      reco_creationdate: null,
      reco_contractdate: null,
      reco_notificationdate: null,
      record_specialties: [],
      record_notifications: [],
      record_zip_code: null,
      record_addresses: [],
      reco_idguiastipo: null,
      reco_agrup: null,
    },
    tmpDatos: null,
    dialogClonar: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
    dialogSalir: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
    },
    alertMsg: {
      text: "",
      type: "success",
      newId: null,
      dismissible: false,
      mostrarAlert: false,
    },
  }),
  methods: {
    ...mapActions("records", [
      "setRecordById",
      "setRecordUpdate",
      "setRecordClone",
    ]),
    ...mapActions("comun", ["setSnackBar"]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),

    /* funcion al pulsar la flecha volver */
    volverPrincipal(salir) {
      // si está seteado a true el parámetro salir salimos, este viene así desde el modal
      if (salir === true) {
        this.$router.push({
          name: "fichas",
          params: { localdata: !this.datosActualizados },
        });
      }
      // si existen cambios sin guardar preguntamos en el modal
      if (this.cambiosSinGuardar) {
        this.dialogSalir = {
          mostrar: true,
          cabecera: "Aviso",
          cuerpo: "Existen cambios pendientes de guardar, ¿Desea continuar?",
        };

        // si están los datos guardados comprobamos si hemos actualizado y volvemos a la lista
      } else {
        this.$router.push({
          name: "fichas",
          params: { localdata: !this.datosActualizados },
        });
      }
    },

    /* actualizar las especialidades una vez modificadas */
    actualizarEsp(datos) {
      // console.log("actualizar esp", datos);
      this.datos.record_specialties = [...datos];
    },

    /** actualiza la ficha de adjuntos */
    actualizaAdjuntos(datos) {
      this.datos.record_files = [...datos];
    },

    /* diálogo para clonar una ficha */
    dialogClonarFicha() {
      this.dialogClonar.cabecera = "Clonar Ficha";
      this.dialogClonar.cuerpo =
        "Esta acción creará un registro nuevo copiando todos los datos del actual.<br/>¿Desea continuar?";
      this.dialogClonar.mostrar = true;
      //si aceptamos ejecutamos el metodo clonar ficha
    },
    /* funcion para clonar una ficha existente */
    clonarFicha() {
      // console.log("clonar aceptado", this.id);
      //return;
      this.setRecordClone(this.id).then((res) => {
        this.dialogClonar.mostrar = false;
        const text = `Clonado registro correctamente.`;
        const type = "success";
        const dismissible = true;
        this.mostrarAlert(text, res, type, dismissible);
        return;
      });
    },
    /* funcion para guardar los datos de una ficha */
    actualizarDatos(data) {
      if (!data) {
        this.cambiosSinGuardar = false;
        return;
      }
      this.cambiosSinGuardar = !_.isEqual(data, this.recordById.record);
      this.tmpDatos = _.cloneDeep(data);
    },
    /* funcion para guardar los datos de una nueva! */
    guardarDatos() {
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }

      this.setRecordUpdate(this.tmpDatos).then((res) => {
        this.cambiosSinGuardar = false;
        this.datosActualizados = true;
        const miSnack = {
          mostrar: true,
          texto: "Guardados los datos correctamente",
          color: "success",
        };
        this.setSnackBar(miSnack);
        this.SET_RECORDS_RELOAD(true);
      });
    },
    /* funcion para cargar los datos al entrar */
    cargarDatos(evt) {
      const currid = evt ? evt.reco_id : this.id;
      this.loading = true;
      this.setRecordById(currid).then((res) => {
        this.datos = { ...res.record };
        this.categorias = res.categorias;
        this.subcategorias = res.subcategorias;
        this.direcciones = res.direcciones;
        this.estado = res.estado;
        this.tipoCentro = res.tipoCentro;
        this.editando = true;
        this.cambiosSinGuardar = false;
        this.tipoGuia = res.tipoGuia;
        this.loading = false;
      });
    },

    /* function para mostrar un alert */
    mostrarAlert(text, newId, type = "success", dismissible = true) {
      this.alertMsg = {
        text,
        newId,
        type,
        dismissible,
        mostrarAlert: false,
      };
      this.alertMsg.mostrarAlert = true;
    },
    /* funcion para ir al registro clonado */
    irNuevoRegistro(datos) {
      this.alertMsg.mostrarAlert = false;
      this.loading = true;
      this.id = datos.id;
      this.cargarDatos();
    },
  },
  computed: {
    /*  ...mapGetters("records", ["recordById"]), */
    ...mapState("records", ["recordById"]),
    /* numero de especialidades para el badge en el tab */
    compNumEsp() {
      //console.log(this.datos.record_specialties);
      if (
        this.datos.record_specialties &&
        this.datos.record_specialties.length === 0
      ) {
        return 0;
      }

      if (!this.datos.record_specialties) {
      }
      const tmpObj = _.groupBy(
        this.datos.record_specialties,
        "resp_idsubcategory"
      );
      return Object.keys(tmpObj).length;
    },
    /* generar el texto de la cabecera */
    textoCabecera() {
      if (this.loading) return;
      if (this.editando === true) {
        return this.datos.reco_name;
      } else {
        return "Ficha / Creando un nuevo registro";
      }
    },
    /* contador de direcciones */
    countDirecciones() {
      if (this.loading) return;
      return this.direcciones.length;
    },
    /* contador de notificaciones */
    countNotificaciones() {
      if (this.loading) return;
      return this.datos.record_notifications.length;
    },
    /* contador de adjuntos */
    countAdjuntos() {
      if (this.loading) return;
      return this.recordById.record.record_files.length;
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    //si no hay id estoy haciendo un alta nueva
    if (!this.id) {
      this.editando = false;
    }
    if (this.$route.params.tab) {
      // console.log("hay un parametro tab", this.$route.params.tab);
      this.activeTab = 2;
    }
    //cargamos los datos desde el método si estamos editando
    this.cargarDatos();
  },

  watch: {
    recordById: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.loading) {
          this.datos = newValue.record;
          this.categorias = newValue.categorias;
          this.subcategorias = newValue.subcategorias;
          this.direcciones = newValue.direcciones;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-btn--example {
  margin: 44px 10px 26px 16px;
}
</style>
